import { z } from 'zod';

export const UsersCollection = 'users';

export const GlobalUserDocument = 'global';

export const BaseUserSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
});
export type BaseUser = z.infer<typeof BaseUserSchema>;

export const UserSchema = BaseUserSchema.merge(z.object({
    id: z.string(),
}));
export type User = z.infer<typeof UserSchema>;

export const UnassignedUser = 'Unassigned';

export const userFirstNameString = (user: User | undefined) => {
    return user?.firstName ?? UnassignedUser;
};

export const userFullNameString = (user: User | undefined) => {
    return user ? `${user.firstName} ${user.lastName}` : UnassignedUser;
};

export const userFirstNameLastInitialString = (user: User | undefined) => {
    return user ? `${user.firstName} ${user.lastName.charAt(0)}.` : UnassignedUser;
};