import { Image, Skeleton } from "@chakra-ui/react";
import { TbUserCircle } from "react-icons/tb";
import { useAuth } from "../firebase/AuthContext";
import { useState } from "react";

interface UserAvatarProps {
    size?: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ size }) => {
    const authContext = useAuth();
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const s = size ? size : '32px';

    const photoURL = authContext?.user?.photoURL;
    if (photoURL) {
        return (
            <Skeleton isLoaded={isImageLoaded} borderRadius='100%' boxShadow='md' boxSize={s}>
                <Image boxSize={s}
                    objectFit='cover'
                    borderRadius='full'
                    boxShadow='md'
                    src={photoURL}
                    alt='User avatar'
                    onLoad={() => setIsImageLoaded(true)}
                />
            </Skeleton>
        );
    } else {
        return <TbUserCircle size={s} />
    }
};