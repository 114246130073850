import './../../index.css';
import { useEffect, useRef } from 'react';
import { Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import { Room } from '../../model/room';

interface ProjectRoomHeaderProps {
    signedIn: boolean;
    selectedRoom: Room | undefined;
    rooms: Room[];
    onSelectRoom: (roomId: string) => void;
    onAddRoom: () => void;
}

const ProjectRoomHeader: React.FC<ProjectRoomHeaderProps> = ({ signedIn, selectedRoom, rooms, onSelectRoom, onAddRoom }) => {

    const tabGroupRef = useRef<any | undefined>(undefined);

    useEffect(() => {
        document.title = "Knack | Project Details";

        const tabGroup = tabGroupRef.current;

        const handleTabShow = (event: any) => {
            const activeTabId = event.detail.name;
            onSelectRoom(activeTabId);
        };

        if (!tabGroup) return;

        // Attach event listener
        tabGroup.addEventListener('sl-tab-show', handleTabShow);

        // Cleanup event listener on component unmount
        return () => {
            tabGroup.removeEventListener('sl-tab-show', handleTabShow);
        };
    }, [onSelectRoom]);

    const roomTabs = () => {
        return rooms.map((room) => {
            return (
                <SlTab key={room.id} slot="nav" panel={room.id} active={selectedRoom?.id === room.id} className='capitalize'>
                    {room.name}
                </SlTab>
            );
        });
    };

    return (
        <div className='flex flex-col gap-2'>

            {signedIn && (
                <div className='flex px-[24px] justify-end'>
                    <Button
                        variant='outline'
                        colorScheme="brand"
                        size={'sm'}
                        aria-label="Add Room"
                        flex='none'
                        leftIcon={<AddIcon />}
                        onClick={() => onAddRoom()}
                    >
                        Room
                    </Button>
                </div>
            )}

            <div className='w-full relative h-[65px] px-[12px] items-center'>
                <SlTabGroup ref={tabGroupRef}>
                    {roomTabs()}
                </SlTabGroup>
            </div>
        </div>
    );
};

export { ProjectRoomHeader };