import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";

interface RoomNoItemsProps {
    signedIn: boolean;
    onAddItem: () => void;
}

export const RoomNoItems: React.FC<RoomNoItemsProps> = ({ signedIn, onAddItem }) => {
    return (
        <div className='w-full relative min-h-[65px]'>
            <Box backgroundColor='secondaryBackground' w='100%' h='100%' zIndex='-10' position='absolute' borderRadius='10' />
            <div className='px-[12px] h-full relative items-center'>
                <div className='flex flex-row h-full justify-center items-center'>
                    {signedIn ? (
                        <div className='flex flex-col gap-6 items-center justify-center py-8'>
                            <Text>No items added to this room</Text>
                            <Button
                                variant='solid'
                                colorScheme="brand"
                                size={'sm'}
                                aria-label="Add Item"
                                flex='none'
                                leftIcon={<AddIcon />}
                                onClick={() => onAddItem()}
                            >
                                Item
                            </Button>
                        </div>
                    ) : (
                        <Text>No items added to this room</Text>
                    )}
                </div>
            </div>
        </div>
    );
};