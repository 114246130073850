import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../firebase/AuthContext';
import { useEffect, useState } from 'react';
import { db } from "../../firebase/firebase";
import { collection, doc, onSnapshot, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { Project as ProjectModel, ProjectSchema, ProjectsCollection, ProjectStatus } from '../../model/project';
import { Divider, Spinner, useToast } from '@chakra-ui/react';
import { signInWithGoogle } from '../../services/auth';
import { projectUsers, ProjectUsers } from '../../services/users';
import { ProjectHeader } from './ProjectHeader';
import { ProjectRoomHeader } from './ProjectRoomHeader';
import { BaseRoomSchema, Room, RoomSchema, RoomsCollection } from '../../model/room';
import { RoomTable } from './room/RoomTable';
import { Item, ItemSchema, ItemsCollection } from '../../model/item';
import { RoomNoItems } from './room/RoomNoItems';
import { ProjectNoRooms } from './ProjectNoRooms';
import { EditRoomModal } from './room/EditRoomModal';
import { RoomInfo } from './room/RoomInfo';
import { RoomImagesModal } from './room/RoomImagesModal';
import { ItemModal } from './item/ItemModal';
import { itemUpdates, ProjectItemUpdates } from '../../services/itemUpdates';

const Project: React.FC = () => {
    const { projectId } = useParams();
    const authContext = useAuth();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const signedIn = authContext !== undefined && authContext.user !== null;
    const [project, setProject] = useState<ProjectModel | undefined>(undefined);
    const [rooms, setRooms] = useState<Room[] | undefined>(undefined);
    const [itemsByRoomId, setItemsByRoomId] = useState<{ [key: string]: Item[] } | undefined>(undefined);
    const [users, setUsers] = useState<ProjectUsers | undefined>(undefined);
    const [projectItemUpdates, setProjectItemUpdates] = useState<ProjectItemUpdates | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);
    const [roomToEdit, setRoomToEdit] = useState<Room | undefined>(undefined);
    const [editRoomModalOpen, setEditRoomModalOpen] = useState(false);
    const [viewRoomPhotosModalOpen, setViewRoomPhotosModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<[Item, Room] | undefined>(undefined);

    useEffect(() => {
        document.title = "Knack | Project Details";
    }, []);

    useEffect(() => {
        let pId = projectId;
        if (location.state) {
            const proj: ProjectModel = location.state;
            pId = proj.id;
            setProject(proj);
        }
        if (pId === undefined) return;

        const unsubscribeFunctions: Array<() => void> = [];

        const unsubscribeProjects = onSnapshot(doc(db, ProjectsCollection, pId), (doc) => {
            const proj = ProjectSchema.parse({ id: doc.id, ...doc.data() });
            setProject(proj);
            setLoading(false);
        });
        unsubscribeFunctions.push(unsubscribeProjects);

        const roomsRef = collection(db, RoomsCollection);
        const roomsQuery = query(
            roomsRef,
            where(BaseRoomSchema.keyof().enum.archived, "!=", true),
            where(BaseRoomSchema.keyof().enum.projectId, "==", pId),
        );
        const unsubscribeRooms = onSnapshot(roomsQuery, (querySnapshot) => {
            let rooms = querySnapshot.docs.map((doc) => {
                return RoomSchema.parse({ id: doc.id, ...doc.data() });
            });
            rooms.sort((a, b) => {
                // Sort ascending by created date
                if (a.created < b.created) return -1;
                if (a.created > b.created) return 1;
                return 0;
            });
            setRooms(rooms);
            if (selectedRoom === undefined && rooms.length > 0) {
                setSelectedRoom(rooms[0]);
            }
        });
        unsubscribeFunctions.push(unsubscribeRooms);

        const itemsRef = collection(db, ItemsCollection);
        const itemsQuery = query(itemsRef, where(ItemSchema.keyof().enum.projectId, "==", pId));
        const unsubscribeItems = onSnapshot(itemsQuery, (querySnapshot) => {
            let itemsByRoomId: { [key: string]: Item[] } = {};
            querySnapshot.docs.forEach((doc) => {
                const item = ItemSchema.parse({ id: doc.id, ...doc.data() });
                if (itemsByRoomId[item.roomId] === undefined) {
                    itemsByRoomId[item.roomId] = [];
                }
                itemsByRoomId[item.roomId].push(item);
            });
            setItemsByRoomId(itemsByRoomId);
        });
        unsubscribeFunctions.push(unsubscribeItems);

        const unsubscribeUsers = projectUsers(pId, (users) => {
            setUsers(users);
        });
        unsubscribeFunctions.push(unsubscribeUsers);

        const unsubscribeItemUpdates = itemUpdates(pId, (updates) => {
            setProjectItemUpdates(updates);
        });
        unsubscribeFunctions.push(unsubscribeItemUpdates);

        // Cleanup all listeners on unmount
        return () => {
            unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
        };
    }, [projectId, location.state]);

    const handleSignIn = async () => {
        try {
            await signInWithGoogle();
        } catch (error: any) {
            if (error.code === 'auth/admin-restricted-operation') {
                toast({
                    title: 'Unauthorized Access',
                    description: "Looks like you don't have access to this site. Contact Mark if you think this is a mistake.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };

    const handleProjectsClicked = () => {
        navigate('/');
    };

    const updateProjectStatus = async (status: ProjectStatus) => {
        if (project) {
            const now = Timestamp.now();
            const updatedProject = { ...project, status, updated: now };
            setProject(updatedProject);
            try {
                const projectRef = doc(db, ProjectsCollection, project.id);
                if (projectRef === undefined) throw new Error('Project not found');
                await updateDoc(projectRef, updatedProject);
            } catch (error) {
                console.error("Error saving project:", error);
                toast({
                    title: 'Error Saving Project',
                    description: "An error occurred while saving your project.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };

    const openAddRoomModal = () => {
        setRoomToEdit(undefined);
        setEditRoomModalOpen(true);
    };

    const openEditRoomModal = () => {
        if (selectedRoom) {
            setRoomToEdit(selectedRoom);
            setEditRoomModalOpen(true);
        }
    };

    const onEditRoomDone = () => {
        setRoomToEdit(undefined);
        setEditRoomModalOpen(false);
    };

    const selectRoomWithId = (roomId: string) => {
        if (rooms === undefined) return;
        const room = rooms.find(r => r.id === roomId);
        if (room !== undefined) {
            setSelectedRoom(room);
        }
    }

    if (!loading && !project) {
        throw new Error('Project not found');
    }

    if (project && rooms && itemsByRoomId && users && projectItemUpdates) {
        const items = selectedRoom ? itemsByRoomId[selectedRoom.id] ?? [] : []

        return (
            <>
                <div className='flex justify-center pb-12'>
                    <div className={`flex flex-col w-[940px] ${signedIn && rooms.length > 0 ? 'gap-0' : 'gap-4'}`}>
                        <ProjectHeader
                            signedIn={signedIn}
                            project={project}
                            onBackToProjects={handleProjectsClicked}
                            onSignIn={handleSignIn}
                            onUpdateProjectStatus={updateProjectStatus}
                        />

                        {selectedRoom === undefined ? (
                            <ProjectNoRooms signedIn={signedIn} onAddRoom={openAddRoomModal} />
                        ) : (
                            <div className='flex flex-col gap-0 w-full'>
                                <ProjectRoomHeader
                                    signedIn={signedIn}
                                    selectedRoom={selectedRoom}
                                    rooms={rooms}
                                    onSelectRoom={(roomId) => selectRoomWithId(roomId)}
                                    onAddRoom={openAddRoomModal}
                                />
                                <div className='px-[12px] pb-[12px]'>
                                    <Divider />
                                </div>
                                <RoomInfo
                                    signedIn={signedIn}
                                    room={selectedRoom}
                                    onEditRoom={openEditRoomModal}
                                    onEditPhotos={openEditRoomModal}
                                    onViewPhotos={() => setViewRoomPhotosModalOpen(true)}
                                />
                                {items.length === 0 ? (
                                    <RoomNoItems
                                        signedIn={signedIn}
                                        onAddItem={() => { }}
                                    />
                                ) : (
                                    <RoomTable
                                        signedIn={signedIn}
                                        items={items}
                                        room={selectedRoom}
                                        users={users}
                                        onAddItem={() => { }}
                                        onSelectItem={(item, room) => { setSelectedItem([item, room]) }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div >
                <EditRoomModal
                    project={project}
                    room={roomToEdit}
                    modalOpen={editRoomModalOpen}
                    onDone={onEditRoomDone}
                />
                <RoomImagesModal
                    room={selectedRoom}
                    modalOpen={viewRoomPhotosModalOpen}
                    onFinish={() => setViewRoomPhotosModalOpen(false)}
                />
                <ItemModal
                    item={selectedItem ? selectedItem[0] : undefined}
                    room={selectedItem ? selectedItem[1] : undefined}
                    users={users}
                    itemUpdates={selectedItem ? projectItemUpdates.itemUpdates[selectedItem[0].id] ?? [] : []}
                    isOpen={selectedItem !== undefined}
                    onClose={() => { setSelectedItem(undefined) }}
                />
            </>
        )
    } else {
        return (
            <div className='flex flex-col h-screen justify-center'>
                <div className='flex flex-row justify-center'>
                    <Spinner
                        thickness="3px"
                        speed='1.0s'
                        emptyColor='gray.100'
                        color='brand.500'
                        size='xl'
                    />
                </div>
            </div>
        )
    }
};

export { Project };