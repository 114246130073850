import { getAdditionalUserInfo, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { auth } from "../firebase/firebase";

export const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        const userCreds = await signInWithPopup(auth, provider);
        const additionalUserInfo = getAdditionalUserInfo(userCreds);
        const token = await userCreds.user.getIdToken();

        const user = {
            ...userCreds.user,
            additionalUserInfo,
        };

        return { user, token };
    } catch (error: any) {
        if (error.code === 'auth/admin-restricted-operation') {
            await signOut(auth);
        }
        throw error;
    }
};

export const signOutWithGoogle = async () => {
    await signOut(auth);
};