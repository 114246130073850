import { Accordion, extendTheme, ThemeConfig } from "@chakra-ui/react"
import { tableTheme } from './table-theme'
import { accordionTheme } from "./accordion-theme";


const config: ThemeConfig = {
    initialColorMode: 'system',
    useSystemColorMode: true,
};
  
export const theme = extendTheme({
    config,
    fonts: {
        body: "Lato, system-ui, sans-serif",
        heading: "Lato, system-ui, sans-serif",
    },
    colors: {
        brand: {
            50: "#f0fbfc",
            100: "#e0f5f6",
            200: "#b3e6e8",
            300: "#80d6da",
            400: "#4dc8cc",
            500: "#52c2c8",
            600: "#4bafb5",
            700: "#43989e",
            800: "#3b7f86",
            900: "#2b5d61",
        },
        gray: {
            50: "#F2F2F4",
            100: "#D9DADE",
            200: "#B5B7BD",
            300: "#90939B",
            400: "#6D7078",
            500: "#494D55",
            600: "#292C33",
            700: "#1F2126",
            800: "#14161A",
            900: "#0A0B0D",
        },
    },
    semanticTokens: {
        colors: {
            secondaryBackground: {
                default: 'gray.50',
                _dark: 'gray.700',
            },
            tertiaryBackground: {
                default: 'gray.100',
                _dark: 'gray.600',
            },
            secondaryText: {
                default: '#444444',
                _dark: '#CCCCCC',
            },
            text: {
                default: '#000000',
                _dark: '#FFFFFF',
            },
            tertiaryText: {
                default: '#666666',
                _dark: 'gray.300',
            },
            tag: {
                default: 'brand.500',
                _dark: 'brand.500',
            },
            inverseText: {
                default: '#FFFFFF',
                _dark: '#000000',
            },
        }
    },
    components: {
        Modal: {
            baseStyle: {
                header: {
                    textTransform: "uppercase",
                    fontWeight: "semibold",
                    fontSize: "3xl",
                },
            },
        },
        Table: tableTheme,
        Accordion: accordionTheme,
    },
})