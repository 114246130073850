import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const variantItems = definePartsStyle((props) => {
    const { colorScheme: c, colorMode } = props

    return {
        
        th: {
            '&[data-is-numeric=true]': {
                textAlign: 'end',
            },
            h: '65px',
            bg: colorMode === 'light' ? `${c}.100` : `${c}.600`,
            fontWeight: 'extrabold',
            fontSize: '16px',
            textTransform: 'none',
        },
        td: {
            '&[data-is-numeric=true]': {
                textAlign: 'end',
            },
        },
        caption: {
            color: colorMode === 'light' ? `${c}.600` : `${c}.100`,
        },
        thead: {
            tr: {
                '&:first-of-type': {
                    'th:first-of-type': {
                        borderTopLeftRadius: '10px',
                    },
                    'th:last-of-type': {
                        borderTopRightRadius: '10px',
                    },
                },
            }
        },
        tbody: {
            tr: {
                '&:last-of-type': {
                    'td:first-of-type': {
                        borderBottomLeftRadius: '10px',
                    },
                    'td:last-of-type': {
                        borderBottomRightRadius: '10px',
                    }
                },
                '&:not(:last-of-type)': {
                    borderBottomWidth: '1px',
                    borderColor: colorMode === 'light' ? `${c}.100` : `${c}.600`,
                },
                'td:first-of-type': {
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                bg: colorMode === 'light' ? `${c}.50` : `${c}.700`,
                h: '70px',
            },
            fontWeight: 'regular',
            fontSize: '16px',
        },
        tfoot: {
            tr: {
                '&:last-of-type': {
                    th: { borderBottomWidth: 0 },
                },
            },
        },
    }
});

const metadata = definePartsStyle((props) => {
    const { colorScheme: c, colorMode } = props

    return {
        
        td: {
            '&[data-is-numeric=true]': {
                textAlign: 'end',
            },
            paddingX: '16px',
        },
        tbody: {
            tr: {
                '&:first-of-type': {
                    'td:first-of-type': {
                        borderTopLeftRadius: '10px',
                    },
                    'td:last-of-type': {
                        borderTopRightRadius: '10px',
                    }
                },
                '&:last-of-type': {
                    'td:first-of-type': {
                        borderBottomLeftRadius: '10px',
                    },
                    'td:last-of-type': {
                        borderBottomRightRadius: '10px',
                    }
                },
                'td:first-of-type': {
                    backgroundColor: colorMode === 'light' ? `${c}.100` : `${c}.600`,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                },
                '&:not(:last-of-type)': {
                    'td:first-of-type': {
                        borderColor: colorMode === 'light' ? `${c}.50` : `${c}.700`,
                        backgroundColor: colorMode === 'light' ? `${c}.100` : `${c}.600`,
                        borderBottomWidth: '1px',
                    },
                    'td:not(:first-of-type)': {
                        borderColor: colorMode === 'light' ? `${c}.100` : `${c}.600`,
                        borderBottomWidth: '1px',
                    },
                },
                bg: colorMode === 'light' ? `${c}.50` : `${c}.700`,
                minH: '48px',
                borderWidth: '0px',
            },
            fontWeight: 'regular',
            fontSize: '14px',
        },
    }
});

export const tableTheme = defineMultiStyleConfig({
    variants: { variantItems, metadata },
});