import { Button, Image, Spinner, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { signInWithGoogle } from "../services/auth";

interface LoginPageProps {
    loading: boolean;
}

const LoginPage: React.FC<LoginPageProps> = ({ loading }) => {
    const [signingIn, setSigningIn] = React.useState(false);
    const toast = useToast();

    useEffect(() => {
        document.title = "Knack | Login";
    }, []);

    return (
        <div className="flex flex-col gap-8 justify-center items-center h-screen">
            <img src="/knack-logo.jpeg" alt="Knack Logo" className="h-32" />
            <Button
                isLoading={loading || signingIn}
                loadingText='Signing in...'
                // color='#3c4043'
                // borderColor='#dadce0'
                fontWeight='regular'
                fontSize='16'
                px='3'
                // colorScheme="brand"
                // variant='ghost'
                // _hover={{ bg: '#f8fafe', borderColor: "#d5e2fa" }}
                spinner={
                    <Spinner
                        speed='1.0s'
                        emptyColor='gray.200'
                        color='#0A'
                        size='md'
                    />
                }
                variant='outline'
                size='lg'
                leftIcon={
                    <Image src="/google.png" className="h-5" alt="Google Logo" />
                }
                onClick={
                    async () => {
                        try {
                            setSigningIn(true);
                            await signInWithGoogle();
                            setSigningIn(false);
                        } catch (error: any) {
                            if (error.code === 'auth/admin-restricted-operation') {
                                toast({
                                    title: 'Unauthorized Access',
                                    description: "Looks like you don't have access to this site. Contact Mark if you think this is a mistake.",
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                });
                                setSigningIn(false);
                            }
                        }
                    }
                }>
                Sign in with Google
            </Button>
        </div>
    );
};

export { LoginPage };