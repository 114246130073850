import { Timestamp } from 'firebase/firestore';
import { z } from 'zod';

export const ItemsCollection = 'items';
export const ItemUpdatesCollection = 'itemUpdates';

export enum ItemStatus {
    Idea = 'idea',
    Researching = 'researching',
    Proposed = 'proposed',
    Selected = 'selected',
    Ordered = 'ordered',
    Shipped = 'shipped',
    Arrived = 'arrived',
    Checked = 'checked',
    Returned = 'returned',
    Refunded = 'refunded',
    cancelled = 'cancelled',
};


export const ItemStateSchema = z.object({
    status: z.nativeEnum(ItemStatus),
    assignee: z.string().optional(),
    updated: z.instanceof(Timestamp),
});
export type ItemState = z.infer<typeof ItemStateSchema>;


export const ItemSchema = z.object({
    id: z.string(),
    roomId: z.string(),
    projectId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    state: ItemStateSchema,
    imageUrls: z.array(z.string().url()),
    url: z.string().url().optional(),
    store: z.string().optional(),
    cost: z.number().optional(),
    shippingCost: z.number().optional(),
    payment: z.string().optional(),
    locationName: z.string().optional(),
    locationAddress: z.string().optional(),
    dateOrdered: z.instanceof(Timestamp).optional(),
    orderNumber: z.string().optional(),
    dateExpected: z.instanceof(Timestamp).optional(),
    dateArrived: z.instanceof(Timestamp).optional(),
    checkedBy: z.string().optional(),
    keepItem: z.boolean().optional(),
    returnedBy: z.string().optional(),
    dateReturned: z.instanceof(Timestamp).optional(),
    refundAmount: z.number().optional(),
    created: z.instanceof(Timestamp),
    updated: z.instanceof(Timestamp),
});
export type Item = z.infer<typeof ItemSchema>;


export const ItemUpdateSchema = z.object({
    id: z.string(),
    itemId: z.string(),
    field: z.string(),
    fieldTitle: z.string().optional(),
    fromValue: z.string().optional(),
    toValue: z.string(),
    updated: z.instanceof(Timestamp),
    updatedBy: z.string(),
});
export type ItemUpdate = z.infer<typeof ItemUpdateSchema>;