import './index.css';
import { useAuth } from './firebase/AuthContext';
import { LoginPage } from './pages/Login';
import { Projects } from './pages/projects/Projects';

const Landing: React.FC = () => {
  const authContext = useAuth();

  if (authContext?.user) {
    return <Projects />;
  } else {
    return <LoginPage loading={!authContext || authContext.loading} />;
  }
}

export default Landing;
