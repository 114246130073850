import { Box, Button, ButtonGroup, Editable, EditableInput, EditablePreview, Flex, Heading, HStack, IconButton, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Tag, Text, Tooltip, useEditableControls, VStack } from "@chakra-ui/react";
import { Item, ItemUpdate } from "../../../model/item";
import { Room } from "../../../model/room";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { TbBrowser, TbBuildingStore, TbCalendarEvent, TbCreditCard, TbIdBadge2, TbLibraryPhoto, TbLocation, TbMailDollar, TbPencil, TbReportMoney, TbStatusChange } from "react-icons/tb";
import { ProjectUsers } from "../../../services/users";
import { userFullNameString } from "../../../model/user";
import { formatDistanceToNow } from "date-fns";
import React, { useState } from "react";

interface ItemModalProps {
    item: Item | undefined;
    room: Room | undefined;
    users: ProjectUsers;
    itemUpdates: ItemUpdate[];
    isOpen: boolean;
    onClose: () => void;
}

export const ItemModal: React.FC<ItemModalProps> = ({ item, room, users, itemUpdates, isOpen, onClose }) => {

    const [loadedImageUrls, setLoadedImageUrls] = React.useState<Set<string>>(new Set());

    const renderAssignee = () => {
        let user = undefined;
        if (item?.state.assignee) {
            user = users?.usersMap[item.state.assignee];
        }
        return (
            <HStack bg='secondaryBackground' borderRadius='full' py='6px' px='12px' ms='12px' spacing='6px'>
                <Heading fontSize='14px' fontWeight='400' textTransform='none' color='tertiaryText'>Assigned to</Heading>
                <Heading fontSize='14px' fontWeight='black' textTransform='capitalize'>{userFullNameString(user)}</Heading>
            </HStack>
        );
    };

    const renderImages = (item: Item) => {
        return (
            <VStack w='100%'>
                <div className="flex justify-center">
                    <div className="flex overflow-x-auto scroll-smooth scroll-p-[12px] scrollbar-none">
                        {item.imageUrls.map((src, i) => (
                            <Skeleton isLoaded={loadedImageUrls.has(src)} borderRadius='5px' boxShadow='md' boxSize='120px'>
                                <Image boxSize='120px'
                                    objectFit='cover'
                                    borderRadius='5px'
                                    boxShadow='md'
                                    src={src}
                                    alt={`Item image ${i + 1}`}
                                    className="thumbnails__image"
                                    onLoad={() => setLoadedImageUrls(prev => new Set<string>([...prev, src]))}
                                />
                            </Skeleton>
                        ))}
                    </div>
                </div>
                <Box alignContent='center'>
                    <Button colorScheme="brand" variant='ghost' size='sm' onClick={() => console.log('Add image')}>
                        View all
                    </Button>
                </Box>
            </VStack>
        );
    };

    const renderDivider = () => {
        return (
            <Box px='24px' w='100%'>
                <Box h='1px' w='100%' bg='tertiaryBackground'></Box>
            </Box>
        );
    };

    const renderIcon = (field: string) => {
        switch (field) {
            case 'cost':
                return <TbReportMoney />;
            case 'dateArrived':
            case 'dateExpected':
            case 'dateOrdered':
            case 'dateReturned':
                return <TbCalendarEvent />;
            case 'imageUrls':
                return <TbLibraryPhoto />;
            case 'locationName':
            case 'locationAddress':
                return <TbLocation />;
            case 'name':
                return <TbIdBadge2 />;
            case 'payment':
                return <TbCreditCard />;
            case 'shippingCost':
                return <TbMailDollar />;
            case 'status':
                return <TbStatusChange />;
            case 'store':
                return <TbBuildingStore />;
            case 'url':
                return <TbBrowser />;
            default:
                return <TbPencil />;
        }
    };

    const renderActivity = (update: ItemUpdate) => {
        const user = users.usersMap[update.updatedBy];
        if (user) {
            return (
                <HStack w='100%' spacing='18px'>
                    {renderIcon(update.field)}
                    <Box fontSize='14px' color='tertiaryText'>
                        <Box as="span" fontWeight='black' color='text'>{user.firstName}</Box>
                        {update.fromValue ? (
                            <>
                                {` changed ${update.fieldTitle ?? update.field} from `}
                                <Box as="span" fontWeight='black' color='text'>{update.fromValue}</Box>
                                {' to '}
                            </>
                        ) : (
                            <>
                                {` set ${update.fieldTitle ?? update.field} to `}
                            </>
                        )}
                        <Box as="span" fontWeight='black' color='text'>{update.toValue}</Box>
                        &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                        <Box as="span" fontSize='13px'>
                            {formatDistanceToNow(update.updated.toDate(), { addSuffix: true })}
                        </Box>
                    </Box>
                </HStack>
            );
        } else {
            return (
                <Text></Text>
            );
        }
    };

    const renderNoActivity = () => {
        return (
            <Text fontSize='14px' color='tertiaryText'>No activity yet.</Text>
        );
    };

    const renderActivityFeed = () => {
        return (
            <VStack w='100%' align='start' px='24px' spacing='24px'>
                <Heading fontSize='18px' fontWeight='black'>Activity</Heading>
                {itemUpdates.length === 0 ? (
                    renderNoActivity()
                ) : (
                    <VStack w='100%' align='start' spacing='24px'>
                        {itemUpdates.map((update, i) =>
                            renderActivity(update)
                        )}
                    </VStack>
                )}
            </VStack>
        );
    };

    const DescriptionField = () => {
        const [value, setValue] = useState("");
        const placeholder = 'Add a description';
        const [editing, setEditing] = useState(false);
        return (
            <Tooltip label="Enter to submit. Esc to cancel." aria-label="Click to edit" isOpen={editing}>
                <Editable
                    defaultValue={item?.description}
                    value={value}
                    onChange={setValue}
                    onEdit={() => setEditing(true)}
                    placeholder={placeholder}
                    textTransform='none'
                    fontSize='14px'
                    fontWeight='400'
                    px='24px'
                    w='100%'
                    onCancel={() => setEditing(false)}
                    onSubmit={(value) => {
                        setEditing(false)
                        console.log(value)
                    }}

                >

                    <EditablePreview
                        color={!value ? "tertiaryText" : "text"}
                        _hover={!value ? { bg: "transparent" } : { bg: "tertiaryBackground" }}
                        fontStyle={!value ? "italic" : "normal"}
                        cursor='pointer'
                        w='100%'
                    >
                        {!value ? placeholder : value}
                    </EditablePreview>

                    <EditableInput />
                </Editable>
            </Tooltip>
        );
    };

    return (
        <Modal
            size='xl'
            closeOnOverlayClick={true}
            isOpen={isOpen}
            onClose={() => onClose()}
        >
            <ModalOverlay />
            {item && room && (
                <ModalContent bg='chakra-body-bg'>
                    <ModalHeader>
                        <VStack align='start' spacing='12px'>
                            <Box bg='secondaryBackground' borderRadius='10px' w='100%'>
                                <VStack align='start' w='100%' pb='24px' pt='12px' ps='24px' pe='12px' spacing='24px'>
                                    <HStack align='start' justify='space-between' w='100%' pe='-12px' spacing='12px'>
                                        <VStack align='start' spacing='4px' pt='12px'>
                                            <Heading fontWeight='extrabold' textTransform='capitalize' fontSize='32px'>{item.name}</Heading>
                                            <Heading fontWeight='400' textTransform='capitalize' fontSize='20px'>{room.name}</Heading>
                                        </VStack>
                                        <IconButton aria-label="Close modal" variant='ghost' icon={<CloseIcon />} onClick={() => onClose()} />
                                    </HStack>
                                    <VStack align='start' spacing='8px'>
                                        <Tag bg='tag' color='inverseText' fontWeight='bold' fontSize='14px' textTransform='capitalize' borderRadius='full' px='12px' py='4px'>
                                            {item?.state.status}
                                        </Tag>
                                        <Heading fontWeight='400' color='tertiaryText' textTransform='none' fontSize='12px'>{`Updated ${formatDistanceToNow(item.state.updated.toDate(), { addSuffix: true })}`}</Heading>
                                    </VStack>
                                </VStack>
                            </Box>
                            {renderAssignee()}
                            <DescriptionField />
                        </VStack>
                    </ModalHeader>
                    <ModalBody>
                        <VStack w='100%' align='start' spacing='24px' pb='24px'>
                            {renderImages(item)}
                            {renderDivider()}
                            {renderActivityFeed()}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button variant='ghost' mr={2} onClick={() => onFinish()}>
                        Cancel
                    </Button>
                    <Button colorScheme="brand" onClick={() => handleProjectSave()}>{project ? 'Save' : 'Add'}</Button> */}
                    </ModalFooter>
                </ModalContent>
            )}
        </Modal>
    );
};