import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { RoomInfoField } from "../../../model/room";

interface RoomInfoDetailsProps {
    fields: RoomInfoField[];
}

const RoomInfoDetails: React.FC<RoomInfoDetailsProps> = ({ fields }) => {

    const url = (value: string) => {
        return value.startsWith('http') ? value : `https://${value}`;
    };

    return (
        <Table variant='metadata' colorScheme='gray'>
            <Tbody>
                {fields.map((field, index) => (
                    <Tr key={index}>
                        <Td textTransform='capitalize'>
                            {field.name}
                        </Td>
                        <Td>
                            {field.isUrl ? (
                                <Link fontSize='14px' fontWeight='400' href={url(field.value)} isExternal>
                                    <div className='flex flex-row items-center gap-2'>
                                        <div>{field.value}</div> <ExternalLinkIcon />
                                    </div>
                                </Link>
                            ) : (
                                field.value
                            )}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

export { RoomInfoDetails };