import { useEffect, useRef, useState } from 'react';
import { projectAddress, Project as ProjectModel, ProjectStatus } from '../../model/project';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, HStack, IconButton, Image, Link, Skeleton, Spinner, Text, Tooltip, useColorModeValue, useToast } from '@chakra-ui/react';
import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { signInWithGoogle } from '../../services/auth';
import { TbEdit, TbUser } from "react-icons/tb";
import { EditProjectModal } from '../projects/EditProjectModal';

const GOOGLE_MAPS_URL = "https://www.google.com/maps/search/?api=1&query=";

interface ProjectHeaderProps {
    signedIn: boolean;
    project: ProjectModel;
    onBackToProjects: () => void;
    onSignIn: () => void;
    onUpdateProjectStatus: (toStatus: ProjectStatus) => void;
}

const ProjectHeader: React.FC<ProjectHeaderProps> = ({ signedIn, project, onBackToProjects, onSignIn, onUpdateProjectStatus }) => {
    const cancelRef = useRef<null>(null);
    const toast = useToast();
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [confirmStatusChange, setConfirmStatusChange] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const imagePlaceholderColor = useColorModeValue('gray.200', 'gray.700');

    useEffect(() => {
        document.title = "Knack | Project Details";
    }, []);

    const address = projectAddress(project);

    const addressURL = address ? GOOGLE_MAPS_URL + encodeURIComponent(address) : undefined;

    const signIn = async () => {
        setSigningIn(true);
        try {
            await signInWithGoogle();
        } catch (error: any) {
            console.error(error);
            if (error.code === 'auth/admin-restricted-operation') {
                toast({
                    title: 'Unauthorized Access',
                    description: "Looks like you don't have access to this site. Contact Mark if you think this is a mistake.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Problem signing in',
                    description: "There was a problem signing in. Please try again.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } finally {
            setSigningIn(false);
        }
    };

    const renderSignInSpinner = () => {
        return (
            <Spinner
                size='sm'
                speed='1.0s'
            />
        );
    };

    const renderStatusUpdateConfirmation = (toStatus: ProjectStatus) => {
        return (
            <AlertDialog
                isOpen={confirmStatusChange}
                leastDestructiveRef={cancelRef}
                onClose={() => setConfirmStatusChange(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {toStatus === ProjectStatus.Archived ? 'Archive Project' : 'Unarchive Project'}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setConfirmStatusChange(false)}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme='brand'
                                ml={3}
                                onClick={() => {
                                    setConfirmStatusChange(false);
                                    onUpdateProjectStatus(toStatus)
                                }}
                            >
                                {toStatus === ProjectStatus.Archived ? 'Archive' : 'Unarchive'}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        );
    };

    return (
        <div>
            <Box backgroundColor='secondaryBackground' className='w-full'>
                <div className='p-[16px]'>
                    {signedIn ? (
                        <>
                            <div className='block sm:hidden'>
                                <Tooltip label='Back to Projects'>
                                    <IconButton
                                        variant='outline'
                                        colorScheme="brand"
                                        aria-label="Sign in"
                                        className='text-lg'
                                        icon={<ChevronLeftIcon boxSize={6} />}
                                        onClick={() => onBackToProjects()}
                                    />
                                </Tooltip>
                            </div>
                            <div className="hidden sm:block">
                                <Button
                                    leftIcon={<ChevronLeftIcon boxSize={6} />}
                                    colorScheme="brand"
                                    variant='ghost'
                                    onClick={() => onBackToProjects()}
                                >
                                    Projects
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className='flex justify-end'>
                            <div className='block sm:hidden'>
                                <Tooltip label='Sign in'>
                                    <IconButton
                                        variant='ghost'
                                        colorScheme="brand"
                                        aria-label="Sign in"
                                        className='text-lg'
                                        isDisabled={signingIn}
                                        icon={signingIn ? renderSignInSpinner() : <TbUser />}
                                        onClick={() => signIn()}
                                    />
                                </Tooltip>
                            </div>
                            <div className="hidden sm:block">
                                <Button
                                    leftIcon={signingIn ? renderSignInSpinner() : <TbUser />}
                                    colorScheme="brand"
                                    variant='ghost'
                                    isDisabled={signingIn}
                                    onClick={() => signIn()}
                                >
                                    Sign In
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Box>
            <div className='w-full relative'>
                <Box backgroundColor='secondaryBackground' className='w-full h-[130px] -z-10 absolute' borderBottomRadius='10' />
                <div className='flex flex-row w-full gap-[20px] px-[24px] relative justify-between items-end'>
                    <div className='flex flex-row w-full gap-[20px]'>
                        <div>
                            {project.imageUrl ? (
                                <Skeleton isLoaded={isImageLoaded} borderRadius='100%' boxShadow='md'>
                                    <Image className="w-[160px] h-[160px]"
                                        objectFit='cover'
                                        borderRadius='100%'
                                        boxShadow='md'
                                        src={project.imageUrl}
                                        alt='Project'
                                        onLoad={() => setIsImageLoaded(true)}
                                    />
                                </Skeleton>
                            ) : (
                                <Box bg={imagePlaceholderColor} borderRadius='100%' boxShadow='md'>
                                    <div className='w-[160px] h-[160px]' />
                                </Box>
                            )}
                        </div>
                        <div className='flex flex-col pt-[15px] gap-[3px]'>
                            <Text fontSize='32px' fontWeight='extrabold'>{project.name}</Text>
                            <div className='flex flex-col gap-[0px]'>
                                <Text fontSize='16px' fontWeight='600' color='secondaryText'>{project.clientName}</Text>
                                {address && addressURL && (
                                    <Link fontSize='14px' fontWeight='400' color='secondaryText' href={addressURL} isExternal>
                                        <div className='flex flex-row items-center gap-2'>
                                            <div>{address}</div> <ExternalLinkIcon />
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                        <EditProjectModal
                            project={project}
                            modalOpen={editModalOpen}
                            onFinish={() => setEditModalOpen(false)}
                        />
                    </div>
                    {signedIn && (
                        <HStack mb='54px'>
                            <Tooltip label='Edit Project'>
                                <IconButton
                                variant='outline'
                                    icon={<TbEdit />}
                                    aria-label={''}
                                    onClick={() => setEditModalOpen(true)}
                                >
                                    {project.status}
                                </IconButton>
                            </Tooltip>
                            <Tooltip label={project.status === ProjectStatus.Active ? 'Archive project' : 'Unarchive project'}>
                                <Button
                                    textTransform='capitalize'
                                    onClick={() => setConfirmStatusChange(true)}>
                                    {project.status}
                                </Button>
                            </Tooltip>
                        </HStack>
                    )}
                </div>
            </div>
            {renderStatusUpdateConfirmation(project.status === ProjectStatus.Active ? ProjectStatus.Archived : ProjectStatus.Active)}
        </div>
    );
};

export { ProjectHeader };