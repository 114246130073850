import { Heading, Text, VStack } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

const ErrorPage: React.FC = () => {
    const error: any = useRouteError();
    console.error(error);

    return (
        <VStack
            justify='center'
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            height="100vh"
        >
            <Heading>Oops!</Heading>
            <Text>Sorry, an unexpected error has occurred.</Text>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </VStack>
    );
};

export { ErrorPage };