import { Button, Table, TableContainer, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Item } from "../../../model/item";
import { useState } from "react";
import { Room } from "../../../model/room";
import { ProjectUsers } from "../../../services/users";
import { userFirstNameString } from "../../../model/user";

interface RoomTableProps {
    signedIn: boolean;
    items: Item[];
    room: Room;
    users: ProjectUsers;
    onAddItem: () => void;
    onSelectItem: (item: Item, room: Room) => void;
}

const RoomTable: React.FC<RoomTableProps> = ({ signedIn, items, room, users, onAddItem, onSelectItem }) => {

    const [sortField, setSortField] = useState<string>('created');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const sortedItems = items.sort((a: any, b: any) => {
        const fieldA = a[sortField];
        const fieldB = b[sortField];
        if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
        if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
    });

    const formatCurrency = (value: number): string => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      };

    const itemRow = (item: Item) => {
        let user = undefined;
        if (item.state.assignee) {
            user = users.usersMap[item.state.assignee];
        }
        return (
            <Tr key={item.id} fontSize={'16px'} cursor='pointer' onClick={() => onSelectItem(item, room)}>
                <Td>{item.name}</Td>
                <Td>{userFirstNameString(user)}</Td>
                <Td textTransform='capitalize'>
                    <Tag bg='tag' color='inverseText' fontWeight='bold' fontSize='14px' textTransform='capitalize' borderRadius='full' px='12px' py='4px'>
                        {item?.state.status}
                    </Tag>
                </Td>
                {(item.cost !== undefined || item.shippingCost !== undefined) ? (
                    <Td>{formatCurrency((item.cost ?? 0) + (item.shippingCost ?? 0))}</Td>
                ) : (
                    <Td>--</Td>
                )}
                
            </Tr>
        );
    }

    return (
        <TableContainer>
            <Table variant='variantItems'>
                <Thead>
                    <Tr>
                        <Th>Item</Th>
                        <Th>Assignee</Th>
                        <Th>Status</Th>
                        {signedIn ? (
                            <Th>
                                <Button
                                    variant='outline'
                                    colorScheme="brand"
                                    size={'sm'}
                                    aria-label="Add Item"
                                    flex='none'
                                    leftIcon={<AddIcon />}
                                    onClick={() => onAddItem()}
                                >
                                    Item
                                </Button>
                            </Th>
                        ) : (
                            <Th>Total Cost</Th>
                        )}
                        
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedItems.map(item => itemRow(item))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export { RoomTable };