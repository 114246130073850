import { useEffect } from "react";
import { PageHeader } from "./PageHeader";

interface PageProps {
    title: string;
    leftAccessory?: React.ReactNode | undefined;
    rightAccessory?: React.ReactNode | undefined;
    children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({title, leftAccessory, rightAccessory, children}) => {
    
    useEffect(() => {
        document.title = `Knack | ${title}`;
    }, [title]);

    return (
        <div className='font-sans'>
            <PageHeader title={title} leftAccessory={leftAccessory} rightAccessory={rightAccessory} />
            <main className="max-w-7xl ms-auto me-auto">
                {children}
            </main>
        </div>
    );
};