import { Box, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Room } from "../../../model/room";
import SlCarousel from '@shoelace-style/shoelace/dist/react/carousel';
import SlCarouselItem from '@shoelace-style/shoelace/dist/react/carousel-item';

interface RoomImagesModalProps {
    room: Room | undefined;
    modalOpen: boolean;
    onFinish: () => void;
}

const RoomImagesModal: React.FC<RoomImagesModalProps> = ({ room, modalOpen, onFinish }) => {
    return (
        <div>
            <Modal size='4xl' closeOnOverlayClick={true} isOpen={modalOpen} onClose={() => onFinish()}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Room Images
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SlCarousel pagination navigation mouse-dragging loop>
                            {room?.imageUrls.map((imageSrc, index) => {
                                return (
                                    <SlCarouselItem key={index}>
                                        <Image src={imageSrc} alt={`Room Image ${index}`} maxW='100%' h='100%' objectFit='contain' />
                                    </SlCarouselItem>
                                );
                            })}
                        </SlCarousel>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export { RoomImagesModal };