import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import { GlobalUserDocument, User, UserSchema, UsersCollection } from "../model/user";
import { db } from "../firebase/firebase";

export interface ProjectUsers {
    global: User[];
    project: User[];
    usersMap: Record<string, User>;
}

export const projectUsers = (projectId: string, onUpdate: (users: ProjectUsers) => void): Unsubscribe => {
    let projectUsers: ProjectUsers = { global: [], project: [], usersMap: {} };

    // Set up the listener for global users
    const globalUnsubscribe = onSnapshot(collection(db, UsersCollection, GlobalUserDocument, UsersCollection), (snapshot) => {
        // Update the global users part of the list
        const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const parsedUsers = users.map(user => UserSchema.parse(user));
        let usersMap = parsedUsers.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, {} as Record<string, User>);
        usersMap = projectUsers.project.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, usersMap);

        projectUsers.global = parsedUsers;
        projectUsers.usersMap = usersMap;

        onUpdate(projectUsers); // Trigger callback with updated data
    });

    // Set up the listener for project users
    const projectUnsubscribe = onSnapshot(collection(db, UsersCollection, projectId, UsersCollection), (snapshot) => {
        // Update the project users part of the list
        const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const parsedUsers = users.map(user => UserSchema.parse(user));
        let usersMap = parsedUsers.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, {} as Record<string, User>);
        usersMap = projectUsers.global.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, usersMap);

        projectUsers.project = parsedUsers;
        projectUsers.usersMap = { ...projectUsers.usersMap, ...usersMap };

        onUpdate(projectUsers); // Trigger callback with updated data
    });

    // Return a function that can unsubscribe both listeners
    return () => {
        globalUnsubscribe();
        projectUnsubscribe();
    };
};