import { Box } from "@chakra-ui/react";
import { useEffect } from "react";

interface PageHeaderProps {
    title: string;
    leftAccessory?: React.ReactNode | undefined;
    rightAccessory?: React.ReactNode | undefined;
}

export const PageHeader: React.FC<PageHeaderProps> = ({title, leftAccessory, rightAccessory}) => {
    
    useEffect(() => {
        document.title = `Knack | ${title}`;
    }, [title]);

    return (
        <Box as='header' bg='chakra-body-bg' borderBottomColor={'chakra-subtle-bg'} borderBottomWidth={'1px'} className="sticky top-0 z-50 w-full p-4 ">
        {/* <header className="sticky top-0 z-50 bg-white w-full p-4 shadow-sm"> */}
            <div className='flex items-center max-w-7xl ms-auto me-auto'>
                {/* Left-aligned content */}
                <div className="flex-1 text-left">
                    {leftAccessory}
                </div>

                {/* Centered title */}
                <div className="flex-none text-center mx-auto font-sans font-semibold uppercase text-2xl md:text-3xl lg:text-4xl">
                    {title}
                </div>

                {/* Right-aligned content */}
                <div className="flex-1 text-right">
                    {rightAccessory}
                </div>
            </div>
        {/* </header> */}
        </Box>
    );
};