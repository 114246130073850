import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { ItemUpdate, ItemUpdateSchema, ItemUpdatesCollection } from "../model/item";

export interface ProjectItemUpdates {
    itemUpdates: Record<string, ItemUpdate[]>;
}

export const itemUpdates = (projectId: string, onUpdate: (updates: ProjectItemUpdates) => void): Unsubscribe => {
    return onSnapshot(collection(db, ItemUpdatesCollection, projectId, ItemUpdatesCollection), (snapshot) => {
        const itemUpdates = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const parsedItemUpdates = itemUpdates.map(update => ItemUpdateSchema.parse(update)).sort((a, b) => {
            if (a.updated > b.updated) return -1;
            if (a.updated < b.updated) return 1;
            return 0;
        });
        let updatesMap = parsedItemUpdates.reduce((acc, update) => {
            let itemUpdates = acc[update.itemId] || [];
            itemUpdates.push(update);
            acc[update.itemId] = itemUpdates;
            return acc;
        }, {} as Record<string, ItemUpdate[]>);

        onUpdate({ itemUpdates: updatesMap });
    });
};