import { useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { Button, Fade, Image, IconButton, Skeleton, Spinner, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Tooltip, Tr, useColorModeValue, useToast } from "@chakra-ui/react";
import { ProjectRow } from "./ProjectRow";
import { TbLibraryPlus, TbLogout, TbUserCircle } from "react-icons/tb";
import { EditProjectModal } from "./EditProjectModal";
import { Project, projectAddress, ProjectsCollection, ProjectStatus, projectStatusFromString } from "../../model/project";
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";
import { signOutWithGoogle } from "../../services/auth";
import { useAuth } from "../../firebase/AuthContext";
import { UserAvatar } from "../../components/UserAvatar";

interface ProjectsPage {

}

const Projects: React.FC<ProjectsPage> = () => {
    const authContext = useAuth();
    const toast = useToast();
    const [tabIndex, setTabIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [activeProjects, setActiveProjects] = useState<Project[]>([]);
    const [archivedProjects, setArchivedProjects] = useState<Project[]>([]);
    const [signingOut, setSigningOut] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const hoverBgColor = useColorModeValue('gray.100', 'gray.900');

    useEffect(() => {
        document.title = "Knack | Projects";
        const unsubscribe = onSnapshot(collection(db, ProjectsCollection), (snapshot) => {
            const projects: any[] = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const active: Project[] = [];
            const archived: Project[] = [];
            projects.forEach(project => {
                if (projectStatusFromString(project.status) === ProjectStatus.Archived) {
                    archived.push(project);
                } else {
                    active.push(project);
                }
            });
            active.sort((a, b) => {
                // Sort descending by updated date
                if (a.updated > b.updated) return -1;
                if (a.updated < b.updated) return 1;
                return 0;
            });
            setActiveProjects(active);
            archived.sort((a, b) => {
                // Sort descending by updated date
                if (a.updated > b.updated) return -1;
                if (a.updated < b.updated) return 1;
                return 0;
            });
            setArchivedProjects(archived);
        });

        return () => unsubscribe(); // Cleanup the listener on unmount
    }, []);

    const signOut = async () => {
        setSigningOut(true);
        try {
            await signOutWithGoogle();
        } catch (error: any) {
            console.error(error);
            toast({
                title: 'Problem signing out',
                description: "There was a problem signing out. Please try again.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setSigningOut(false);
    };

    const handleTabsChange = (index: number) => {
        setTabIndex(index);
    };

    const onAddProjectFinish = () => {
        setModalOpen(false);
    };

    const renderProjectsRows = (projects: Project[]) => {
        return projects.map((project) => {
            return (
                <Tr
                    key={project.id}
                    cursor='pointer'
                    _hover={{ bg: hoverBgColor, color: 'brand.700' }}
                >
                    <Td>
                        <Link to={`/projects/${project.id}`} state={project}>
                            <ProjectRow
                                imageUrl={project.imageUrl}
                                name={project.name}
                                clientName={project.clientName}
                                address={projectAddress(project)}
                            />
                        </Link>
                    </Td>
                </Tr >
            );
        });
    };

    const renderProjectsTable = (projects: Project[]) => {
        return (
            <TableContainer>
                <Table size='lg' variant='simple'>
                    <Tbody>
                        {renderProjectsRows(projects)}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    const renderSignOutSpinner = () => {
        return (
            <Spinner
                size='sm'
                speed='1.0s'
            />
        );
    };

    return (
        <Page
            title="Projects"
            leftAccessory={
                <>
                    <div className='block sm:hidden'>
                        <Tooltip label='Add a project'>
                            <IconButton
                                variant='ghost'
                                colorScheme="brand"
                                aria-label="Add project"
                                className='text-lg'
                                icon={<TbLibraryPlus size='32px' />}
                                onClick={() => setModalOpen(true)}
                            />
                        </Tooltip>
                    </div>
                    <div className="hidden sm:block">
                        <Button
                            leftIcon={<AddIcon />}
                            colorScheme="brand"
                            variant='solid'
                            onClick={() => setModalOpen(true)}
                        >
                            Project
                        </Button>
                    </div>
                </>
            }
            rightAccessory={
                <Tooltip label='Sign out'>
                    <Button
                        variant='unstyled'
                        isDisabled={signingOut}
                        onClick={() => signOut()}
                    >
                        <UserAvatar />
                    </Button>
                </Tooltip>
            }>
            <Tabs
                variant='soft-rounded'
                align='center'
                className='pt-4'
                colorScheme="brand"
                onChange={handleTabsChange}
                index={tabIndex}
            >
                <TabList>
                    <Tab>Active</Tab>
                    <Tab>Archived</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Fade in={tabIndex === 0}>
                            {renderProjectsTable(activeProjects)}
                        </Fade>
                    </TabPanel>
                    <TabPanel>
                        <Fade in={tabIndex === 1}>
                            {renderProjectsTable(archivedProjects)}
                        </Fade>
                    </TabPanel>
                </TabPanels>

            </Tabs>
            <EditProjectModal
                project={undefined}
                modalOpen={modalOpen}
                onFinish={onAddProjectFinish}
            />
        </Page>
    );
};

export { Projects };