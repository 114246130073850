import { Skeleton, Image, Box, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";

interface ProjectRowProps {
    imageUrl: string | undefined;
    name: string;
    clientName: string;
    address: string | undefined;
}

const ProjectRow: React.FC<ProjectRowProps> = ({imageUrl, name, clientName, address}) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const imagePlaceholderColor = useColorModeValue('gray.200', 'gray.700');

    return (
        <div className='flex flex-row gap-4'>
            {imageUrl ? (
                <Skeleton isLoaded={isImageLoaded} borderRadius='100%' boxShadow='md'>
                    <Image className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
                        objectFit='cover'
                        borderRadius='100%'
                        boxShadow='md'
                        src={imageUrl}
                        alt='Project'
                        onLoad={() => setIsImageLoaded(true)}
                    />
                </Skeleton>
            ) : (
                <Box bg={imagePlaceholderColor} borderRadius='100%' boxShadow='md'>
                    <div className='w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24' />
                </Box>
            )}
            
            <div className="flex flex-col items-start justify-center gap-0">
                <div className="font-black text-lg md:text-xl">{name}</div>
                <div className='flex flex-col gap-0'>
                    <div className='text-base md:text-lg'>{clientName}</div>
                    {address && (
                        <div className='hidden sm:block text-xs md:text-sm truncate'>{address}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export { ProjectRow };