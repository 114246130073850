import { Timestamp } from 'firebase/firestore';
import { z } from 'zod';
import { ProjectSchema } from './project';
import { RoomSchema } from './room';
import { ItemSchema } from './item';

export const ChangesCollection = 'changes';

export enum ChangeType {
    Project = 'project',
    Room = 'room',
    Item = 'item',
};

export const BaseChangeSchema = z.object({
    timestamp: z.instanceof(Timestamp),
    byUser: z.string(),
});
export type BaseChange = z.infer<typeof BaseChangeSchema>;

export const ProjectChangeSchema = BaseChangeSchema.merge(z.object({
    type: z.literal(ChangeType.Project),
    from: ProjectSchema.optional(),
    to: ProjectSchema.optional(),
}));
export type ProjectChange = z.infer<typeof ProjectChangeSchema>;

export const RoomChangeSchema = BaseChangeSchema.merge(z.object({
    type: z.literal(ChangeType.Room),
    from: RoomSchema.optional(),
    to: RoomSchema.optional(),
}));
export type RoomChange = z.infer<typeof RoomChangeSchema>;

export const ItemChangeSchema = BaseChangeSchema.merge(z.object({
    type: z.literal(ChangeType.Item),
    from: ItemSchema.optional(),
    to: ItemSchema.optional(),
}));
export type ItemChange = z.infer<typeof ItemChangeSchema>;