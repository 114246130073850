import { Timestamp } from 'firebase/firestore';
import { z } from 'zod';

export const RoomsCollection = 'rooms';

export const RoomInfoFieldSchema = z.object({
    name: z.string(),
    value: z.string(),
    isUrl: z.boolean(),
});
export type RoomInfoField = z.infer<typeof RoomInfoFieldSchema>;

export const BaseRoomSchema = z.object({
    name: z.string(),
    projectId: z.string(),
    imageUrls: z.array(z.string().url()),
    dimensions: z.string().optional(),
    floorplannerUrl: z.string().url().optional(),
    otherFields: z.array(RoomInfoFieldSchema),
    created: z.instanceof(Timestamp),
    updated: z.instanceof(Timestamp),
    archived: z.boolean(),
});
export type BaseRoom = z.infer<typeof BaseRoomSchema>;

export const RoomSchema = BaseRoomSchema.merge(z.object({
    id: z.string(),
}));
export type Room = z.infer<typeof RoomSchema>;

export interface RoomInfoFieldUpdate {
    name: string | undefined;
    value: string | undefined;
    isUrl: boolean | undefined;
}

export interface RoomUpdate {
    name?: string | undefined;
    imageUrls?: string[] | undefined;
    dimensions?: string | undefined;
    floorplannerUrl?: string | undefined;
    otherFields?: RoomInfoFieldUpdate[] | undefined;
    archived?: boolean;
}

export const roomUpdate = (room: Room): RoomUpdate => {
    return {
        name: room.name,
        imageUrls: [...room.imageUrls],
        dimensions: room.dimensions,
        floorplannerUrl: room.floorplannerUrl,
        otherFields: [...room.otherFields],
        archived: room.archived,
    };
};