import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { v4 as uuid } from 'uuid';
import { ProjectStatus, BaseProject, BaseProjectSchema, Project, ProjectsCollection, ProjectUpdate, projectUpdate, ProjectSchema } from "../../model/project";
import { ChangesCollection, ChangeType, ProjectChange } from "../../model/change";
import { useAuth } from "../../firebase/AuthContext";

interface EditProjectModalProps {
    project: Project | undefined;
    modalOpen: boolean;
    onFinish: () => void;
}

const EditProjectModal: React.FC<EditProjectModalProps> = ({ project, modalOpen, onFinish }) => {
    const [inProgressProject, setInProgressProject] = useState<ProjectUpdate>({ state: 'GA' });
    const [projectNameIsInvalid, setProjectNameIsInvalid] = useState(false);
    const [clientNameIsInvalid, setClientNameIsInvalid] = useState(false);
    const [image, setImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const authContext = useAuth();
    const toast = useToast();

    const cleanupAndDone = useCallback(() => {
        setInProgressProject({ state: 'GA' });
        setImage(null);
        setImagePreview(null);
        setSaving(false);
        setSaved(false);
        onFinish();
    }, [onFinish]);

    useEffect(() => {
        if (!saved) return;
        const timer = setTimeout(() => {
            cleanupAndDone();
        }, 2000);
        return () => clearTimeout(timer);
    }, [saved, cleanupAndDone]);

    useEffect(() => {
        setInProgressProject(project ? projectUpdate(project) : {});
        setImagePreview(project?.imageUrl ?? null);
    }, [project]);

    const isProjectNameValid = (): boolean => {
        return !!inProgressProject.name && inProgressProject.name.length > 0;
    };

    const isClientNameValid = (): boolean => {
        return !!inProgressProject.clientName && inProgressProject.clientName.length > 0;
    };

    const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files || files.length === 0) {
            setImage(null);
            setImagePreview(null);
            return;
        };
        const file = files[0];
        if (file && file.type.startsWith('image/')) {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        } else {
            setImage(null);
            setImagePreview(null);
            toast({
                title: 'Invalid File Type',
                description: "Please select an image.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, name: e.target.value });
        setProjectNameIsInvalid(false);
    };

    const handleClientNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, clientName: e.target.value });
        setClientNameIsInvalid(false);
    };

    const handleClientEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, clientEmail: e.target.value });
    };

    const handleClientPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, clientPhone: e.target.value });
    };

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, address: e.target.value });
    };

    const handleAddress2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, address2: e.target.value });
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, city: e.target.value, state: 'GA' });
    };

    const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setInProgressProject({ ...inProgressProject, state: e.target.value });
    };

    const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInProgressProject({ ...inProgressProject, zip: e.target.value });
    };

    const handleProjectSave = async () => {
        setProjectNameIsInvalid(!isProjectNameValid());
        setClientNameIsInvalid(!isClientNameValid());

        if (!isProjectNameValid() || !isClientNameValid()) return;

        setSaving(true);

        let imageUrl = project ? project.imageUrl : undefined;
        if (image) {
            const storage = getStorage();
            const storageRef = ref(storage, `images/${uuid()}`);

            try {
                const result = await uploadBytes(storageRef, image);
                imageUrl = await getDownloadURL(result.ref);
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }

        const now = Timestamp.now();

        const status = project ? project.status : ProjectStatus.Active;
        const created = project ? project.created : now;

        let updatedProject: BaseProject = {
            name: inProgressProject.name!,
            clientName: inProgressProject.clientName!,
            status: status,
            created: created,
            updated: now,
        };

        if (inProgressProject.clientEmail) {
            updatedProject.clientEmail = inProgressProject.clientEmail;
        }
        if (inProgressProject.clientPhone) {
            updatedProject.clientPhone = inProgressProject.clientPhone;
        }
        if (inProgressProject.address) {
            updatedProject.address = inProgressProject.address;
        }
        if (inProgressProject.address2) {
            updatedProject.address2 = inProgressProject.address2;
        }
        if (inProgressProject.city) {
            updatedProject.city = inProgressProject.city;
        }
        if (inProgressProject.state) {
            updatedProject.state = inProgressProject.state;
        }
        if (inProgressProject.zip) {
            updatedProject.zip = inProgressProject.zip;
        }
        if (imageUrl) {
            updatedProject.imageUrl = imageUrl;
        }

        try {
            updatedProject = BaseProjectSchema.parse(updatedProject);
        } catch (error) {
            console.error("Error validating project:", error);
            toast({
                title: 'Invalid Project',
                description: "An error occurred while validating your project.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setSaving(false);
            return;
        }

        try {
            let projectRef;
            if (project) {
                projectRef = doc(db, ProjectsCollection, project.id);
                if (projectRef === undefined) throw new Error('Project not found');
                await setDoc(projectRef, updatedProject);
            } else {
                const projectsRef = collection(db, ProjectsCollection);
                projectRef = await addDoc(projectsRef, updatedProject);
            }
            setSaved(true);

            const savedProjectSnapshot = await getDoc(projectRef);
            const savedProject = ProjectSchema.parse(savedProjectSnapshot.data());

            const change: ProjectChange = {
                type: ChangeType.Project,
                timestamp: now,
                from: project,
                to: savedProject,
                byUser: authContext?.user?.uid ?? 'unknown',
            };
            const changesRef = collection(db, ChangesCollection);
            await addDoc(changesRef, change);
            
        } catch (error) {
            console.error("Error saving project:", error);
            toast({
                title: 'Error Saving Project',
                description: "An error occurred while saving your project.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const stateDropdown = () => {
        return (
            <Select value={inProgressProject.state} onChange={handleStateChange}>
                <option value='AK'>AK</option>
                <option value='AL'>AL</option>
                <option value='AR'>AR</option>
                <option value='AZ'>AZ</option>
                <option value='CA'>CA</option>
                <option value='CO'>CO</option>
                <option value='CT'>CT</option>
                <option value='DE'>DE</option>
                <option value='FL'>FL</option>
                <option value='GA'>GA</option>
                <option value='HI'>HI</option>
                <option value='IA'>IA</option>
                <option value='ID'>ID</option>
                <option value='IL'>IL</option>
                <option value='IN'>IN</option>
                <option value='KS'>KS</option>
                <option value='KY'>KY</option>
                <option value='LA'>LA</option>
                <option value='MA'>MA</option>
                <option value='MD'>MD</option>
                <option value='ME'>ME</option>
                <option value='MI'>MI</option>
                <option value='MN'>MN</option>
                <option value='MO'>MO</option>
                <option value='MS'>MS</option>
                <option value='MT'>MT</option>
                <option value='NC'>NC</option>
                <option value='ND'>ND</option>
                <option value='NE'>NE</option>
                <option value='NH'>NH</option>
                <option value='NJ'>NJ</option>
                <option value='NM'>NM</option>
                <option value='NV'>NV</option>
                <option value='NY'>NY</option>
                <option value='OH'>OH</option>
                <option value='OK'>OK</option>
                <option value='OR'>OR</option>
                <option value='PA'>PA</option>
                <option value='RI'>RI</option>
                <option value='SC'>SC</option>
                <option value='SD'>SD</option>
                <option value='TN'>TN</option>
                <option value='TX'>TX</option>
                <option value='UT'>UT</option>
                <option value='VA'>VA</option>
                <option value='VT'>VT</option>
                <option value='WA'>WA</option>
                <option value='WI'>WI</option>
                <option value='WV'>WV</option>
                <option value='WY'>WY</option>
            </Select>
        );
    };

    return (
        <Modal size='xl' closeOnOverlayClick={true} isOpen={modalOpen} onClose={() => onFinish()}>
            <ModalOverlay />
            <ModalContent>
                {saved ? (
                    <ModalBody>
                        <VStack spacing={6} py={8}>
                            <CheckCircleIcon boxSize='42px' color='green' />
                            <VStack spacing={0}>
                                <Text fontSize='xl' fontWeight='semibold'>Saved!</Text>
                                <Text fontSize='lg'>Your project has been added!</Text>
                            </VStack>
                        </VStack>
                    </ModalBody>
                ) : saving ? (
                    <ModalBody>
                        <VStack spacing={6} py={8}>
                            <Spinner
                                thickness="3px"
                                speed='1.0s'
                                emptyColor='gray.100'
                                color='brand.500'
                                size='xl'
                            />
                            <VStack spacing={0}>
                                <Text fontSize='xl' fontWeight='semibold'>Saving</Text>
                                <Text fontSize='lg'>Please wait while we save your project.</Text>
                            </VStack>
                        </VStack>
                    </ModalBody>
                ) : (
                    <>
                        <ModalHeader>
                            {project ? 'Edit Project' : 'Add a Project'}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack spacing={4} align='stretch'>
                                <FormControl>
                                    <VStack spacing={4} align='stretch'>
                                        {imagePreview && (
                                            <Image
                                                src={imagePreview}
                                                alt="Image preview"
                                                borderRadius={'100%'}
                                                boxShadow='md'
                                                h="150px"
                                                w="150px"
                                                objectFit='cover'
                                            />
                                        )}
                                        <Box>
                                            <Button
                                                as="label"
                                                htmlFor="file-upload"
                                                colorScheme="brand"
                                                variant='outline'
                                            >
                                                Add Photo
                                            </Button>
                                            <Input
                                                id="file-upload"
                                                type="file"
                                                accept="image/*"
                                                display="none"
                                                onChange={handlePhotoChange}
                                            />
                                        </Box>
                                    </VStack>
                                </FormControl>
                                <FormControl isRequired isInvalid={projectNameIsInvalid}>
                                    <FormLabel>Project Name</FormLabel>
                                    <Input value={inProgressProject.name ?? ''} onChange={handleNameChange} />
                                    {projectNameIsInvalid && (
                                        <FormErrorMessage>Project name is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl isRequired isInvalid={clientNameIsInvalid}>
                                    <FormLabel>Client Name</FormLabel>
                                    <Input value={inProgressProject.clientName ?? ''} onChange={handleClientNameChange} />
                                    {clientNameIsInvalid && (
                                        <FormErrorMessage>Client name is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Client Email</FormLabel>
                                    <Input value={inProgressProject.clientEmail ?? ''} type='email' onChange={handleClientEmailChange} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Client Phone</FormLabel>
                                    <Input value={inProgressProject.clientPhone ?? ''} type='tel' onChange={handleClientPhoneChange} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Address</FormLabel>
                                    <VStack spacing={2}>
                                        <Input value={inProgressProject.address ?? ''} placeholder="1234 Main St" onChange={handleAddressChange} />
                                        <Input value={inProgressProject.address2 ?? ''} placeholder="Apartment, studio, or floor" onChange={handleAddress2Change} />
                                    </VStack>
                                </FormControl>
                                <Flex gap='2'>
                                    <FormControl flex='1'>
                                        <FormLabel>City</FormLabel>
                                        <Input value={inProgressProject.city ?? ''} onChange={handleCityChange} />
                                    </FormControl>
                                    <FormControl w='76px'>
                                        <FormLabel>State</FormLabel>
                                        {stateDropdown()}
                                    </FormControl>
                                    <FormControl w='96px'>
                                        <FormLabel>Zip</FormLabel>
                                        <Input value={inProgressProject.zip ?? ''} type='number' onChange={handleZipChange} />
                                    </FormControl>
                                </Flex>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={2} onClick={() => onFinish()}>
                                Cancel
                            </Button>
                            <Button colorScheme="brand" onClick={() => handleProjectSave()}>{project ? 'Save' : 'Add'}</Button>
                        </ModalFooter>
                    </>
                )}

            </ModalContent>
        </Modal>
    );
};

export { EditProjectModal };