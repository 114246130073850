import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Flex, Image, Skeleton, VStack } from "@chakra-ui/react";
import { Room, RoomInfoField } from "../../../model/room";
import { useState } from "react";
import { AddIcon, EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { RoomInfoDetails } from "./RoomInfoDetails";

interface RoomInfoProps {
    signedIn: boolean;
    room: Room;
    onEditRoom: () => void;
    onEditPhotos: () => void;
    onViewPhotos: () => void;
}

const RoomInfo: React.FC<RoomInfoProps> = ({ signedIn, room, onEditRoom, onEditPhotos, onViewPhotos }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    let roomInfoDetails: RoomInfoField[] = [];
    if (room.dimensions) {
        roomInfoDetails.push({ name: 'Dimensions', value: room.dimensions, isUrl: false });
    }
    if (room.floorplannerUrl) {
        roomInfoDetails.push({ name: 'Floorplanner', value: room.floorplannerUrl, isUrl: true });
    }
    room.otherFields.forEach((field) => {
        roomInfoDetails.push(field);
    });

    return (
        <div>
            <Accordion allowToggle>
                <AccordionItem>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton justifyContent='center'>

                                <Button
                                    colorScheme="brand"
                                    variant='ghost'
                                    size='sm'
                                    rightIcon={<InfoOutlineIcon />}
                                    onClick={() => { }}
                                >
                                    {isExpanded ? 'Hide room information' : 'Show room information'}
                                </Button>
                            </AccordionButton>
                            <AccordionPanel pb={4} paddingX='0px'>
                                <div className='flex flex-row gap-4 justify-between items-start'>
                                    <div className='flex flex-col gap-4 justify-between items-start'>
                                        {roomInfoDetails.length > 0 && (
                                            <RoomInfoDetails fields={roomInfoDetails} />
                                        )}
                                        {signedIn && (
                                            <Button
                                                colorScheme="brand"
                                                variant={roomInfoDetails.length > 0 ? 'outline' : 'solid'}
                                                size='sm'
                                                leftIcon={<EditIcon />}
                                                onClick={() => onEditRoom()}
                                            >
                                                Edit room
                                            </Button>
                                        )}
                                    </div>
                                    <VStack bg='secondaryBackground' borderRadius='10px' p='10px' spacing='10px'>
                                        {room.imageUrls.length > 0 ? (
                                            <>
                                                <Skeleton isLoaded={isImageLoaded} borderRadius='5px'>
                                                    <Image className="w-[200px] h-[200px]"
                                                        objectFit='cover'
                                                        borderRadius='5px'
                                                        src={room.imageUrls[0]}
                                                        alt='Room image'
                                                        onLoad={() => setIsImageLoaded(true)}
                                                    />
                                                </Skeleton>
                                                <Button
                                                    colorScheme="brand"
                                                    variant={signedIn ? 'outline' : 'ghost'}
                                                    size='sm'
                                                    w='200px'
                                                    onClick={() => onViewPhotos()}
                                                >
                                                    View images
                                                </Button>
                                                {signedIn && (
                                                    <Button
                                                        colorScheme="brand"
                                                        variant='ghost'
                                                        size='sm'
                                                        w='200px'
                                                        leftIcon={<EditIcon />}
                                                        onClick={() => onEditPhotos()}
                                                    >
                                                        Edit images
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className='flex w-[200px] h-[200px] justify-center items-center text-[14px] rounded-[5px]'>
                                                    No images
                                                </div>
                                                {signedIn && (
                                                    <Button
                                                        colorScheme="brand"
                                                        variant='solid'
                                                        size='sm'
                                                        w='200px'
                                                        leftIcon={<AddIcon />}
                                                        onClick={() => onEditPhotos()}
                                                    >
                                                        Add images
                                                    </Button>
                                                )}
                                            </>
                                        )}


                                    </VStack>
                                </div>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export { RoomInfo };