import { Timestamp } from 'firebase/firestore';
import { z } from 'zod';

export const ProjectsCollection = 'projects';

export enum ProjectStatus {
    Active = 'active',
    Archived = 'archived',
};

export const BaseProjectSchema = z.object({
    name: z.string(),
    clientName: z.string(),
    clientEmail: z.string().optional(),
    clientPhone: z.string().optional(),
    address: z.string().optional(),
    address2: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zip: z.string().optional(),
    imageUrl: z.string().url().optional(),
    status: z.nativeEnum(ProjectStatus),
    created: z.instanceof(Timestamp),
    updated: z.instanceof(Timestamp),
});
export type BaseProject = z.infer<typeof BaseProjectSchema>;

export const ProjectSchema = BaseProjectSchema.merge(z.object({
    id: z.string(),
}));
export type Project = z.infer<typeof ProjectSchema>;

export interface ProjectUpdate {
    name?: string | undefined;
    clientName?: string | undefined;
    clientEmail?: string | undefined;
    clientPhone?: string | undefined;
    address?: string | undefined;
    address2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    imageUrl?: string | undefined;
}

export const projectUpdate = (project: Project): ProjectUpdate => {
    return {
        name: project.name,
        clientName: project.clientName,
        clientEmail: project.clientEmail,
        clientPhone: project.clientPhone,
        address: project.address,
        address2: project.address2,
        city: project.city,
        state: project.state,
        zip: project.zip,
        imageUrl: project.imageUrl,
    };
};

export function projectStatusFromString(value: string): ProjectStatus {
    switch (value) {
        case ProjectStatus.Active: return ProjectStatus.Active;
        case ProjectStatus.Archived: return ProjectStatus.Archived;
        default: throw new Error(`Unknown project status: ${value}`);
    }
}

export const projectAddress = (project: Project) => {
    let address = '';
    if (project.address) {
        address += project.address;
    }
    if (project.address2) {
        if (address) address += ', ';
        address += project.address2;
    }
    if (project.city) {
        if (address) address += ', ';
        address += project.city;
    }
    if (project.state) {
        if (address) address += ', ';
        address += project.state;
    }
    if (project.zip) {
        if (address) address += ' ';
        address += project.zip;
    }

    if (!address) return undefined;

    return address;
};