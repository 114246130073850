import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Spinner,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { addDoc, collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { Project } from "../../../model/project";
import { BaseRoom, BaseRoomSchema, Room, RoomInfoField, RoomInfoFieldUpdate, RoomSchema, RoomsCollection, roomUpdate, RoomUpdate } from "../../../model/room";
import { TbTrash } from "react-icons/tb";
import isURL from 'validator/lib/isURL';
import { v4 as uuid } from 'uuid';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useAuth } from "../../../firebase/AuthContext";
import { ChangesCollection, ChangeType, RoomChange } from "../../../model/change";

interface EditRoomModalProps {
    project: Project,
    room: Room | undefined,
    modalOpen: boolean;
    onDone: () => void;
}

const EditRoomModal: React.FC<EditRoomModalProps> = ({ project, room, modalOpen, onDone }) => {
    const [inProgressRoom, setInProgressRoom] = useState<RoomUpdate>({});
    const [shouldValidate, setShouldValidate] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
    const [imagesToUpload, setImagesToUpload] = useState<{ [key: string]: File }>({});
    const authContext = useAuth();
    const toast = useToast();
    const cancelRef = useRef<null>(null);

    const cleanupAndDone = useCallback(() => {
        setInProgressRoom({});
        setShouldValidate(false);
        setSaving(false);
        setSaved(false);
        setDeleting(false);
        setImagePreviews([]);
        setImagesToDelete([]);
        setImagesToUpload({});
        onDone();
    }, [onDone]);

    useEffect(() => {
        if (!saved) return;
        const timer = setTimeout(() => {
            cleanupAndDone();
        }, 2000);
        return () => clearTimeout(timer);
    }, [saved, cleanupAndDone]);

    useEffect(() => {
        setInProgressRoom(room ? roomUpdate(room) : {});
        setImagePreviews(room ? room.imageUrls : []);
    }, [room]);

    const isRoomNameValid = (): boolean => {
        return !!inProgressRoom.name && inProgressRoom.name.length > 0;
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value === '' ? undefined : e.target.value;
        setInProgressRoom({ ...inProgressRoom, name });
        setShouldValidate(false);
    };

    const handleDimensionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dimensions = e.target.value === '' ? undefined : e.target.value;
        setInProgressRoom({ ...inProgressRoom, dimensions });
        setShouldValidate(false);
    };

    const handleFloorplannerUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const floorplannerUrl = e.target.value === '' ? undefined : e.target.value;
        setInProgressRoom({ ...inProgressRoom, floorplannerUrl });
        setShouldValidate(false);
    };

    const handleOtherFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let otherFields = inProgressRoom.otherFields ?? [];
        let field = otherFields[index];
        field.name = e.target.value === '' ? undefined : e.target.value;
        otherFields[index] = field;
        setInProgressRoom({ ...inProgressRoom, otherFields: otherFields });
        setShouldValidate(false);
    };

    const handleOtherFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let otherFields = inProgressRoom.otherFields ?? [];
        let field = otherFields[index];
        field.value = e.target.value === '' ? undefined : e.target.value;
        otherFields[index] = field;
        setInProgressRoom({ ...inProgressRoom, otherFields: otherFields });
        setShouldValidate(false);
    };

    const handleOtherFieldIsUrlChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let otherFields = inProgressRoom.otherFields ?? [];
        let field = otherFields[index];
        field.isUrl = e.target.checked;
        otherFields[index] = field;
        setInProgressRoom({ ...inProgressRoom, otherFields: otherFields });
        setShouldValidate(false);
    };

    const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files || files.length === 0) {
            return;
        };
        const file = files[0];
        if (file && file.type.startsWith('image/')) {
            const fileURL = URL.createObjectURL(file);
            setImagePreviews([...imagePreviews, fileURL]);
            setImagesToUpload({ ...imagesToUpload, [fileURL]: file });
        } else {
            toast({
                title: 'Invalid File Type',
                description: "Please select an image.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handlePhotoRemove = (imageSrc: string) => {
        const newImagePreviews = imagePreviews.filter((imagePreview) => imagePreview !== imageSrc);
        setImagePreviews(newImagePreviews);
        if (imageSrc in imagesToUpload) {
            // If the image needs to be uploaded, then obviously it hasn't been uploaded yet.
            // Simply remove it from the list of images to upload.
            const newImagesToUpload = { ...imagesToUpload };
            delete newImagesToUpload[imageSrc];
            setImagesToUpload(newImagesToUpload);
        } else {
            // Otherwise, the image is already uploaded and needs to be deleted.
            setImagesToDelete([...imagesToDelete, imageSrc]);
        }
    };

    const addOtherField = () => {
        let otherFields = inProgressRoom.otherFields ?? [];
        otherFields.push({ name: '', value: '', isUrl: false });
        setInProgressRoom({ ...inProgressRoom, otherFields: otherFields });
        setShouldValidate(false);
    };

    const removeOtherField = (index: number) => {
        let otherFields = inProgressRoom.otherFields ?? [];
        otherFields.splice(index, 1);
        setInProgressRoom({ ...inProgressRoom, otherFields: otherFields });
        setShouldValidate(false);
    };

    const isFloorplannerUrlValid = (): boolean => {
        if (!inProgressRoom.floorplannerUrl || inProgressRoom.floorplannerUrl.length === 0) return true;
        return isValueValidURL(inProgressRoom.floorplannerUrl);
    };

    const isOtherFieldNameValid = (field: RoomInfoFieldUpdate): boolean => {
        if (!field.name) return false;
        return field.name.length > 0;
    };

    const isValueValidLength = (value: string | undefined): boolean => {
        if (!value) return false;
        return value.length > 0;
    };

    const isValueValidURL = (value: string | undefined): boolean => {
        if (!value) return false;
        return isURL(value);
    };

    const isOtherFieldValueValid = (field: RoomInfoFieldUpdate): boolean => {
        if (!isValueValidLength(field.value)) return false;
        return field.isUrl ? isValueValidURL(field.value) : true;
    };

    const areOtherFieldsValid = (): boolean => {
        if (!inProgressRoom.otherFields) return true;
        return inProgressRoom.otherFields.every((field) => {
            return isValueValidLength(field.name) && isOtherFieldValueValid(field);
        });
    };

    const handleRoomSave = async () => {
        setShouldValidate(true);
        if (!isRoomNameValid()) return;
        if (!areOtherFieldsValid()) return;

        let otherFields: RoomInfoField[]
        try {
            otherFields = inProgressRoom.otherFields?.map((field) => {
                if (!field.name || !field.value) throw new Error('Invalid field');
                return { name: field.name, value: field.value, isUrl: field.isUrl ?? false };
            }) ?? [];
        } catch (error) {
            return;
        }

        setSaving(true);

        // First, delete any images that need to be deleted
        imagesToDelete.forEach(async (imageSrc) => {
            const storage = getStorage();
            const imageRef = ref(storage, imageSrc);
            try {
                await deleteObject(imageRef);
            } catch (error) {
                console.error("Error deleting image:", error);
            }
        });

        // Next, upload any new images
        let imageUrls;
        try {
            imageUrls = await Promise.all(imagePreviews.map(async (imageSrc) => {
                if (imageSrc in imagesToUpload) {
                    const image = imagesToUpload[imageSrc];
                    const storage = getStorage();
                    const storageRef = ref(storage, `images/${uuid()}`);
                    const result = await uploadBytes(storageRef, image);
                    const imageUrl = await getDownloadURL(result.ref);
                    return imageUrl;
                } else {
                    return imageSrc;
                }
            }));
        } catch (error) {
            console.error("Error uploading image:", error);
            toast({
                title: 'Error Saving Room',
                description: "An error occurred while uploading one of the images.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setSaving(false);
            return;
        }

        const now = Timestamp.now();

        const projectId = room ? room.projectId : project.id;
        const created = room ? room.created : now;

        let updatedRoom: BaseRoom = {
            name: inProgressRoom.name!,
            projectId: projectId,
            imageUrls: imageUrls,
            otherFields: otherFields,
            created: created,
            updated: now,
            archived: false,
        };

        if (inProgressRoom.dimensions) {
            updatedRoom.dimensions = inProgressRoom.dimensions;
        }

        if (inProgressRoom.floorplannerUrl) {
            updatedRoom.floorplannerUrl = inProgressRoom.floorplannerUrl;
        }

        try {
            updatedRoom = BaseRoomSchema.parse(updatedRoom);
        } catch (error) {
            console.error("Error validating room:", error);
            toast({
                title: 'Error Saving Room',
                description: "An error occurred while validating your room.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setSaving(false);
            return;
        }

        try {
            let roomRef;
            if (room) {
                roomRef = doc(db, RoomsCollection, room.id);
                if (roomRef === undefined) throw new Error('Room not found');
                await setDoc(roomRef, updatedRoom);
            } else {
                const roomsRef = collection(db, RoomsCollection);
                roomRef = await addDoc(roomsRef, updatedRoom);
            }
            setSaved(true);

            const savedRoomSnapshot = await getDoc(roomRef);
            const savedRoom = RoomSchema.parse(savedRoomSnapshot.data());

            const change: RoomChange = {
                type: ChangeType.Room,
                timestamp: now,
                from: room,
                to: savedRoom,
                byUser: authContext?.user?.uid ?? 'unknown',
            };
            const changesRef = collection(db, ChangesCollection);
            await addDoc(changesRef, change);
            
        } catch (error) {
            console.error("Error saving room:", error);
            toast({
                title: 'Error Saving Room',
                description: "An error occurred while saving your room.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setSaving(false);
        }
    };

    const handleRoomDeleteClicked = () => {
        setConfirmDelete(true);
    };

    const handleRoomDeleteConfirmed = async () => {
        setConfirmDelete(false);
        if (!room) return;
        setDeleting(true);
        setSaving(true);

        let updatedRoom: BaseRoom = { ...room, archived: true };

        try {
            updatedRoom = BaseRoomSchema.parse(updatedRoom);
        } catch (error) {
            console.error("Error validating room:", error);
            toast({
                title: 'Error Saving Room',
                description: "An error occurred while validating your room.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setDeleting(false);
            setSaving(false);
            return;
        }

        try {
            const roomRef = doc(db, RoomsCollection, room.id);
            if (roomRef === undefined) throw new Error('Room not found');
            await setDoc(roomRef, updatedRoom);
            setSaved(true);
        } catch (error) {
            console.error("Error deleting room:", error);
            toast({
                title: 'Error Deleting Room',
                description: "An error occurred while deleting your room.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setDeleting(false);
            setSaving(false);
        }
    };

    const addOtherFieldButtonDisabled = (): boolean => {
        if (!inProgressRoom.otherFields) return false;
        if (inProgressRoom.otherFields.length === 0) return false;
        const hasEmptyField = inProgressRoom.otherFields.some((field) => {
            return !field.name || field.name.length === 0 || !field.value || field.value.length === 0;
        });
        return hasEmptyField;
    };

    const renderOtherFields = () => {
        if (!inProgressRoom.otherFields) return null;
        return (
            <VStack spacing={8} align='stretch' w='100%'>
                {inProgressRoom.otherFields.map((field, index) => {
                    return (
                        <VStack key={`${index}`} gap={2} align='stretch' borderRadius='10px' padding='12px' bg='tertiaryBackground' flex='1'>
                            <HStack align='end'>
                                <FormControl isRequired isInvalid={shouldValidate && !isOtherFieldNameValid(field)}>
                                    <FormLabel>Name</FormLabel>
                                    <Input value={field.name ?? ''} onChange={(e) => handleOtherFieldNameChange(e, index)} />
                                    {shouldValidate && !isOtherFieldNameValid(field) && (
                                        <FormErrorMessage>Field name is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </HStack>
                            <FormControl isRequired isInvalid={shouldValidate && !isOtherFieldValueValid(field)}>
                                <FormLabel>Value</FormLabel>
                                <Input value={field.value ?? ''} onChange={(e) => handleOtherFieldValueChange(e, index)} />
                                {shouldValidate && !isValueValidLength(field.value) && (
                                    <FormErrorMessage>Field value is required.</FormErrorMessage>
                                )}
                                {shouldValidate && isValueValidLength(field.value) && !isOtherFieldValueValid(field) && (
                                    <FormErrorMessage>Field value is not a valid URL.</FormErrorMessage>
                                )}
                            </FormControl>
                            <HStack justify='space-between'>
                                <FormControl >
                                    <FormLabel>Is URL?</FormLabel>
                                    <Checkbox colorScheme='brand' size='lg' isChecked={field.isUrl ?? false} onChange={(e) => handleOtherFieldIsUrlChange(e, index)} />
                                </FormControl>
                                <IconButton
                                    aria-label='Delete Field'
                                    icon={<TbTrash />}
                                    variant='ghost'
                                    colorScheme='red'
                                    onClick={() => {
                                        removeOtherField(index);
                                    }}
                                />
                            </HStack>
                        </VStack>



                    );
                })}
            </VStack>
        );
    }

    return (
        <>
            <Modal size='xl' closeOnOverlayClick={true} isOpen={modalOpen} onClose={() => cleanupAndDone()}>
                <ModalOverlay />
                <ModalContent>
                    {saved ? (
                        <ModalBody>
                            <VStack spacing={6} py={8}>
                                <CheckCircleIcon boxSize='42px' color='green' />
                                <VStack spacing={0}>
                                    <Text fontSize='xl' fontWeight='semibold'>{deleting ? 'Deleted!' : 'Saved!'}</Text>
                                    <Text fontSize='lg'>{`Your room has been ${deleting ? 'deleted!' : 'saved!'}`}</Text>
                                </VStack>
                            </VStack>
                        </ModalBody>
                    ) : saving ? (
                        <ModalBody>
                            <VStack spacing={6} py={8}>
                                <Spinner
                                    thickness="3px"
                                    speed='1.0s'
                                    emptyColor='gray.100'
                                    color='brand.500'
                                    size='xl'
                                />
                                <VStack spacing={0}>
                                    <Text fontSize='xl' fontWeight='semibold'>{deleting ? 'Deleting' : 'Saving'}</Text>
                                    <Text fontSize='lg'>{`Please wait while we ${deleting ? 'delete' : 'save'} your room.`}</Text>
                                </VStack>
                            </VStack>
                        </ModalBody>
                    ) : (
                        <>
                            <ModalHeader>
                                {room ? 'Edit Room' : 'Add a Room'}
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <VStack spacing={4}>
                                    <FormControl>
                                        <VStack spacing={4} align='stretch'>
                                            {imagePreviews.length > 0 && (
                                                <HStack overflowX='auto'>
                                                    {[...imagePreviews].reverse().map((imagePreview) => {
                                                        return (
                                                            <Box key={imagePreview} position="relative">
                                                                <Image
                                                                    src={imagePreview}
                                                                    alt="Image preview"
                                                                    h="150px"
                                                                    w="150px"
                                                                    objectFit='cover'
                                                                />
                                                                <IconButton
                                                                    aria-label='Delete Image'
                                                                    icon={<TbTrash />}
                                                                    size='sm'
                                                                    position="absolute"
                                                                    top="2"
                                                                    right="2"
                                                                    colorScheme='red'
                                                                    boxShadow='md'
                                                                    onClick={() => handlePhotoRemove(imagePreview)}
                                                                />
                                                            </Box>
                                                        )
                                                    })}
                                                </HStack>
                                            )}
                                            <Box>
                                                <Button
                                                    as="label"
                                                    htmlFor="file-upload"
                                                    colorScheme="brand"
                                                    variant='outline'
                                                >
                                                    Add Photo
                                                </Button>
                                                <Input
                                                    id="file-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    display="none"
                                                    onChange={handlePhotoChange}
                                                />
                                            </Box>
                                        </VStack>
                                    </FormControl>
                                    <FormControl isRequired isInvalid={shouldValidate && !isRoomNameValid()}>
                                        <FormLabel>Room Name</FormLabel>
                                        <Input value={inProgressRoom.name ?? ''} onChange={handleNameChange} />
                                        {shouldValidate && !isRoomNameValid() && (
                                            <FormErrorMessage>Room name is required.</FormErrorMessage>
                                        )}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Dimensions</FormLabel>
                                        <Input value={inProgressRoom.dimensions ?? ''} onChange={handleDimensionsChange} placeholder={"12’ x 30’, 144” x 360”, etc."} />
                                    </FormControl>
                                    <FormControl isInvalid={shouldValidate && !isFloorplannerUrlValid()}>
                                        <FormLabel>Floorplanner URL</FormLabel>
                                        <Input value={inProgressRoom.floorplannerUrl ?? ''} type='url' onChange={handleFloorplannerUrlChange} />
                                        {shouldValidate && !isFloorplannerUrlValid() && (
                                            <FormErrorMessage>Not a valid URL.</FormErrorMessage>
                                        )}
                                    </FormControl>
                                    {renderOtherFields()}
                                    <Button
                                        colorScheme='brand'
                                        variant='outline'
                                        onClick={addOtherField}
                                        isDisabled={addOtherFieldButtonDisabled()}
                                    >
                                        Add Field
                                    </Button>
                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                {room && (
                                    <>
                                        <Button variant='outline' colorScheme="red" mr={2} leftIcon={<TbTrash />} onClick={() => handleRoomDeleteClicked()}>
                                            Delete Room
                                        </Button>
                                        <Spacer />
                                    </>
                                )}
                                <Button variant='ghost' mr={2} onClick={() => cleanupAndDone()}>
                                    Cancel
                                </Button>
                                <Button colorScheme="brand" onClick={() => handleRoomSave()}>{room ? 'Save' : 'Add'}</Button>
                            </ModalFooter>
                        </>
                    )}

                </ModalContent>
            </Modal>
            <AlertDialog
                isOpen={confirmDelete}
                leastDestructiveRef={cancelRef}
                onClose={() => setConfirmDelete(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Room
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setConfirmDelete(false)}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={() => handleRoomDeleteConfirmed()} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export { EditRoomModal };