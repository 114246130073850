import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
    const { colorScheme: c, colorMode } = props

    return {
        container: {
            borderTopWidth: "0px",
            borderColor: "inherit",
            _last: {
                borderBottomWidth: "0px",
            },
        },
        button: {
            transitionProperty: "common",
            transitionDuration: "normal",
            fontSize: "md",
            _focusVisible: {
                boxShadow: "outline",
            },
            _hover: {
                bg: colorMode === 'light' ? "blackAlpha.0" : "whiteAlpha.0",
            },
            _disabled: {
                opacity: 0.4,
                cursor: "not-allowed",
            },
            px: "4",
            py: "2",
        },
        panel: {
            pt: "2",
            px: "4",
            pb: "5",
        },
        icon: {
            fontSize: "1.25em",
        },
    }
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });